import React, { ReactChild, useState } from "react";
import Col from "../Col/Col";
import { Link } from "gatsby";
import ig__icon from "@/img/ico/ig__icon.svg";
import fb__icon from "@/img/ico/fb__icon.svg";
import yt__icon from "@/img/ico/yt__icon.svg";
import addToMailchimp from "gatsby-plugin-mailchimp";
import {
  deleteCookiesConsent,
  getIsCookieConsent,
} from "../CookieConsent/CookieConsent";

interface FooterProps {
  className?: string;
  children?: ReactChild | ReactChild[];
  colFlex: boolean;
}

const Footer = ({ className, children, colFlex }: FooterProps) => {
  let colFlexFlag = "";

  const [input, setInput] = useState("");
  const [message, setMessage] = useState("");

  if (colFlex) {
    colFlexFlag = "colFlex";
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    email: string,
  ) => {
    e.preventDefault();
    const result = await addToMailchimp(email);

    if (result.result === "success") {
      setMessage("Thank you for subscribing!");
    } else {
      setMessage("Something wen't wrong, please try again later");
    }
    setTimeout(() => {
      setMessage(undefined);
    }, 4000);
    setInput("");
  };

  return (
    <footer className="Footer">
      {/*
      <div className="Footer__headline wrap notVisible">
        <h4>very briefly</h4>
        <h2>Ecomtrack is cloud-based platform providing <span>accurate campaing tracking</span> for ecommerce stores.</h2>
        <Button
        {...{
          variant: "blue",
          link: "/demo-call",
          text: "Get started",
          size: "small"
        }}
        />
      </div>

      */}

      <div className={`${colFlexFlag} wrap`}>
        <Col size="60">
          <div className="Footer__links">
            <Col size="33">
              <p>
                <strong>Platform</strong>
              </p>
              <Link to="/why-ecomtrack/">Why ecomtrack</Link>
              <Link to="/solution/">Solutions</Link>
              <Link to="/integration/">Integrations</Link>
            </Col>

            <Col size="33">
              <p>
                <strong>News</strong>
              </p>
              <a href="https://blog.ecomtrack.io/">Blog</a>
              <a href="https://ecomtrack.ladesk.com/">Help Center</a>
              <a href="https://blog.ecomtrack.io/">Product Updates</a>
            </Col>

            <Col size="33">
              <p>
                <strong>App</strong>
              </p>
              <a href={global.app_url}>Login</a>
              <a href="https://affiliates.ecomtrack.io/signup">Affiliates</a>
              <Link to="/contact/">Contact Us</Link>
            </Col>
          </div>
        </Col>

        <Col size="40">
          <h4>Subscribe to our newsletter</h4>
          {!getIsCookieConsent("gatsby-gdpr-mailchimp") ? (
            <p
              style={{
                color: "white",
                textDecoration: "underline",
                cursor: "pointer",
                margin: 0,
              }}
              onClick={deleteCookiesConsent}
            >
              Enable cookies to subscribe to our newsletter
            </p>
          ) : (
            <div className="Footer__signup">
              <form onSubmit={e => handleSubmit(e, input)}>
                <input
                  type="email"
                  name="email"
                  placeholder="your email"
                  value={input}
                  onChange={e => setInput(e.target.value)}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {message && <p>{message}</p>}
                  <input
                    type="submit"
                    name="submit"
                    value="Send"
                    className="Button__transparent Button--regular"
                  />
                </div>
              </form>
            </div>
          )}
        </Col>
      </div>
      <div className="wrap Footer__bottom">
        <p>©2023 ecomtrack a QIRIQ Inc. Brand </p>
        <Link to="/terms-of-use/">Terms of use</Link>
        <Link to="/privacy-policy/">Privacy Policy</Link>

        <div className="Footer__icons">
          <a href="#!" className="notVisible">
            <img src={ig__icon} />
          </a>
          <a href="https://www.facebook.com/ecomtrack.io/">
            <img src={fb__icon} alt="facebook icon"/>
          </a>
          <a href="#!" className="notVisible">
            <img src={yt__icon} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
