import React, { ReactChild, useEffect, useState } from "react";
import { Link } from "gatsby";
import Header__logo__image from "@/img/logoWhite.svg";
import FixedHeader__logo__image from "@/img/logo.svg";
import subMenuBlog from "@/img/ico/subMenuBlog.svg";
import subMenuUpdates from "@/img/ico/subMenuUpdates.svg";
import subMenuHelp from "@/img/ico/subMenuHelp.svg";

interface HeaderProps {
  className?: string;
  children?: ReactChild | ReactChild[];
  colFlex?: boolean;
  alwaysFixed?: boolean;
  homePage?: boolean;
  isWhyEcomtrack?: boolean;
  isPricing?: boolean;
  isSolution?: boolean;
  isIntegration?: boolean;
}

const Header = ({
  alwaysFixed,
  isWhyEcomtrack,
  isPricing,
  isSolution,
  isIntegration,
}: HeaderProps) => {
  // HANDLIG FIXED HEADER

  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let handleScroll = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth > 768) {
        if (window.scrollY > 0 || alwaysFixed) {
          setIsHeaderFixed(true);
        } else {
          setIsHeaderFixed(false);
        }
      } else {
        if (window.scrollY > 1500) {
          setIsHeaderFixed(true);
        } else {
          setIsHeaderFixed(false);
        }
      }
    }
  };

  // END OF HANDLING FIXED HEADER

  const [isMobile, setIsMobile] = useState(false);
  const [isMobileMenuShown, setIsMobileMenuShown] = useState(false);

  const handleMobileMenuShown = () => {
    setIsMobileMenuShown((prev) => !prev);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) || window.innerWidth < 768
      );
    }
  }, []);

  const getHeaderClassNames = () => {
    let classes = "Header"
    if (isHeaderFixed && !isMobile || alwaysFixed) {
      classes += " Fixed"
    }
    if (isHeaderFixed && isMobile) {
      classes += " FixedMobile"
    }
    return classes
  }

  return (
    <nav
      className={getHeaderClassNames()}
    >
      <div className="wrap">
        <Link to="/">
          <img
            className="Header__logo"
            alt="Ecomtrack logo"
            src={
              ((isHeaderFixed) || (alwaysFixed)) && !isMobile
                ? FixedHeader__logo__image
                : Header__logo__image
            }
          />
        </Link>

        <div
          className={`Header__menuIcon ${
            isMobileMenuShown && isMobile ? "Header__menuIconClose" : ""
          }`}
          onClick={handleMobileMenuShown}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>

        <ul className={`${isMobileMenuShown && isMobile ? "shown" : ""}`}>
          <li>
            <Link
              className={`baseLink ${isWhyEcomtrack ? "linkActive" : ""}`}
              to="/why-ecomtrack/"
            >
              Why ecomtrack
            </Link>
          </li>
          <li>
            <Link
              className={`baseLink ${isSolution ? "linkActive" : ""}`}
              to="/solution/"
            >
              Solutions
            </Link>
          </li>
          <li>
            <Link
              className={`baseLink ${isIntegration ? "linkActive" : ""}`}
              to="/integration/"
            >
              Integrations
            </Link>
          </li>
          <li className={`Header__subMenu`}>
            <a className="Header__subMenuLink">Resources</a>
            <div className="Header__subMenuItem">
              <a href="https://ecomtrack.ghost.io/">
                <img src={subMenuBlog} alt="Blog icon"/>
                <div>
                  <p>Blog</p>
                  <p className="Header__subMenuItem-description">
                    Never miss the hottest news
                  </p>
                </div>
              </a>
              <a href="https://ecomtrack.ghost.io/">
                <img src={subMenuUpdates} alt="Product update icon"/>
                <div>
                  <p>Product updates</p>
                  <p className="Header__subMenuItem-description">
                    Follow the continuous progress
                  </p>
                </div>
              </a>
              <a href="https://ecomtrack.ladesk.com/">
                <img src={subMenuHelp} alt="Help center icon"/>
                <div>
                  <p>Help Center</p>
                  <p className="Header__subMenuItem-description">
                    Learn about all useful features
                  </p>
                </div>
              </a>
            </div>
          </li>
          <li>
            <Link
              className={`baseLink ${isPricing ? "linkActive" : ""}`}
              to="/pricing/"
            >
              Pricing
            </Link>
          </li>
          <li className="Header__spacer">
            <Link className="baseLink" to="/demo-call/">
              Demo Call
            </Link>
          </li>
          <li className="Button__opaq">
            <a href={`${global.app_url}/register`}>Start tracking</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
